
// eslint-disable-next-line import/no-extraneous-dependencies
import ExcelJS from 'exceljs';

export default {
  data() {
    return {
    };
  },
  methods: {
    async exportToExcel(name, tableData, tableColumns) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Лист данных');
      const columns = tableColumns.filter((column) => column.label !== '');
      // Заголовки столбцов
      const headerRow = worksheet.addRow(columns.map((column) => column.label));
      headerRow.eachCell((cell) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FF808080' } // Серый цвет
        };
        cell.font = {
          color: { argb: 'FFFFFFFF' } // Белый цвет шрифта
        };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
      });
      const data = await this.flattenTableData(tableData);
      // Добавление данных
      data.forEach((row) => {
        // eslint-disable-next-line no-nested-ternary
        const colomnRow = worksheet.addRow(columns.map((column) => ((column.property?.split('.').length > 1) 
            ? ((column.property.split('.').length === 2) ? ((row[column.property.split('.')[0]])?.[column.property.split('.')[1]]) || '' 
                : ((row[column.property.split('.')[0]])?.[column.property.split('.')[1]]?.[column.property.split('.')[2]]) || '')
            : (column?.formatter ? column.formatter(row, column, row[column.property]) || '' : row[column.property] || '')), 'i'));
        colomnRow.eachCell((cell) => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
        });
      });
       // Установка ширины столбцов по содержимому
       worksheet.columns.forEach((column) => {
        const arr = [];
        arr.push(...column.values.map((value) => (value || false ? value.toString().length : 0)));
        const maxLength = Math.max(...arr.filter((val) => val));
        column.width = maxLength < 10 ? 10 : maxLength; // Минимальная ширина столбца
      });
      // Генерация файла Excel
      const buffer = await workbook.xlsx.writeBuffer();

      this.downloadExcelFile(buffer, name);
    },
    flattenTableData(tableData, level = 0) {
      let result = [];
       tableData.forEach((item) => {
        item.level =  level;
        result.push(item);
        if (item.children && item.children.length > 0) {
          result =  result.concat(this.flattenTableData(item.children, level + 1));
        }
      });

      return result;
    },
    async exportToExcelTree(name, tableData) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Лист данных');

      const data = this.flattenTreeData(tableData);
      // Заполнение данными
      data.forEach((item) => {
        const arrcol = []; 
        for (let i = 0; i < item.level; i++) {
          arrcol.push(null);
        }
        arrcol.push(item.name);
        worksheet.addRow(arrcol);
      });
      const buffer = await workbook.xlsx.writeBuffer();
      this.downloadExcelFile(buffer, name);
    },
    flattenTreeData(treeData, level = 0) {
      let result = [];
      treeData.forEach((item) => {
        result.push({
          name: item.name,
          level
        });

        if (item.children && item.children.length > 0) {
          result = result.concat(this.flattenTreeData(item.children, level + 1));
        }
      });

      return result;
    },
    downloadExcelFile(buffer, filename) {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      // Создание ссылки для скачивания
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${filename}.xlsx`;
      link.click();
      window.URL.revokeObjectURL(link.href);
    }
  }
};
